import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import { GatsbyImage } from "gatsby-plugin-image";

import "swiper/css";
import "swiper/css/navigation";

import { toCurrency } from "../../components/product-detail/ProductInfo";

export default function NewOffers() {
  const data = useStaticQuery(graphql`
    query SwiperQuery {
      allStrapiKnife(filter: { offer: { eq: "new" } }) {
        edges {
          node {
            name
            strapiId
            images {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 200)
                }
              }
            }
            price
            cat {
              title
            }
          }
        }
      }
    }
  `);

  const newGoods = data.allStrapiKnife.edges;

  return (
    <Swiper
      slidesPerView={4}
      spaceBetween={20}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      loop={true}
      modules={[Navigation, Autoplay]}
      navigation
      breakpoints={{
        320: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          width: 768,
          slidesPerView: 3,
        },
        1200: {
          width: 1200,
          slidesPerView: 4,
        },
        1800: {
          width: 1800,
          slidesPerView: 4,
        },
      }}
    >
      {newGoods.map((it) => {
        return (
          <SwiperSlide key={it.node.strapiId}>
            <div className="block">
              <Link to={`/product/${it.node.strapiId}`}>
                <div className="p-4">
                  <GatsbyImage
                    image={
                      it.node.images[0].localFile.childImageSharp
                        .gatsbyImageData
                    }
                    title={it.node.name}
                    alt={it.node.name}
                  />
                </div>
              </Link>
              <div className="font-header font-bold">
                {toCurrency(it.node.price)}
              </div>
              <div className="p-3 text-sm">
                <Link to={`/product/${it.node.strapiId}`}>{it.node.name}</Link>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
