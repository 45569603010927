import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage } from "gatsby-plugin-image";

import { Autoplay, Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const Slides = () => {
  const sliders = useStaticQuery(graphql`
    query QuerySlider {
      allStrapiSlider {
        edges {
          node {
            slide {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            strapiId
            knife {
              name
              id
            }
          }
        }
      }
    }
  `);

  const slider = sliders.allStrapiSlider.edges;

  return (
    <section className="max-w-7xl mx-auto">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        pagination={{ clickable: true }}
        modules={[Autoplay, Pagination]}
      >
        {slider.map((it) => {
          return (
            <SwiperSlide key={it.node.strapiId}>
              <a href={`/product/${it.node.knife.id}`}>
                <GatsbyImage
                  image={
                    it.node.slide.localFile.childImageSharp.gatsbyImageData
                  }
                  alt={it.node.knife.name}
                  title={it.node.knife.name}
                />
              </a>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Slides;
