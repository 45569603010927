import React from "react";
import Slides from "./modules/slider";
import NewOffers from "./modules/swiper";

import imgBg from "../images/banner-1.jpg";

const MainPage = () => {
  return (
    <>
      <Slides />
      <section className="md:py-16 py-5 px-5 md:px-0 bg-gray-100">
        <div className="container mx-auto max-w-7xl">
          <h1 className="md:text-4xl text-2xl font-bold font-header md:pb-10 pb-4">
            Новинки
          </h1>
          <div className="flex">
            <NewOffers />
          </div>
        </div>
      </section>
      <section className="md:py-32 py-10 bg-graymachete">
        <div className="container mx-auto max-w-7xl">
          <div className="md:flex md:space-x-16">
            <div className="md:w-1/2">
              <img
                src={imgBg}
                className="w-full"
                alt="Уникальные наборы ножей"
              />
            </div>
            <div className="flex flex-col md:space-y-10 text-whitemachete px-10 md:px-0">
              <span className="block text-3xl font-bold font-header py-6 md:py-0">
                Уникальные наборы ножей
              </span>
              <span className="text-gray-400 max-w-xl text-lg">
                Ассортимент нашей компании заслужил у пользователей репутацию
                качественных и надежных инструментов, пригодных в самых
                различных областях. Широкий ассортимент брендов удовлетворит
                любые запросы наших клиентов.
              </span>
              <div className="py-8">
                <button
                  type="button"
                  className="py-3 px-5 font-header font-semibold bg-redmachete bg-opacity-30 text-gray-100 hover:bg-opacity-100"
                >
                  Подробнее
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="md:py-10 bg-gray-100 px-4 md:px-0">
        <div className="max-w-7xl mx-auto relative">
          <h1 className="text-4xl font-bold font-header py-10">
            Новости и статьи
          </h1>
          <div className="md:grid md:grid-cols-4 md:gap-4 py-8 font-header">
            <div className="flex flex-col bg-white">
              <div className="w-full">
                <img src={news1} className="bg-cover w-full" />
              </div>
              <div className="px-5 py-4">
                <div className="font-bold border-b-2 uppercase border-opacity-70 border-redmachete w-1/3 text-sm text-gray-500">
                  Новости
                </div>
                <div className="py-2 font-bold text-lg">
                  Новая торговая марка - Viper
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-white">
              <div className="w-full">
                <img src={news2} className="bg-cover w-full" />
              </div>
              <div className="px-5 py-4">
                <div className="font-bold border-b-2 uppercase border-opacity-70 border-redmachete w-1/3 text-sm text-gray-500">
                  Новости
                </div>
                <div className="py-2 font-bold text-lg">
                  Новая торговая марка - Viper
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-white">
              <div className="w-full">
                <img src={news3} className="bg-cover w-full" />
              </div>
              <div className="px-5 py-4">
                <div className="font-bold border-b-2 uppercase border-opacity-70 border-redmachete w-1/3 text-sm text-gray-500">
                  Новости
                </div>
                <div className="py-2 font-bold text-lg">
                  Новая торговая марка - Viper
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-white">
              <div className="w-full">
                <img src={news1} className="bg-cover w-full" />
              </div>
              <div className="px-5 py-4">
                <div className="font-bold border-b-2 uppercase border-opacity-70 border-redmachete w-1/3 text-sm text-gray-500">
                  Новости
                </div>
                <div className="py-2 font-bold text-lg">
                  Новая торговая марка - Viper
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default MainPage;
